import {
  Widget,
  CheckableContext,
  CheckableWidget,
  ReactRouterContextWidget,
  FailFastPromise,
} from './widgets/widget';
import AsyncComponent from './async-component';
import BottomButton from './widgets/bottom-button';
import Button from './widgets/button';
import CheckBox from './widgets/check-box';
import CheckMenuItem from './widgets/check-menu-item';
import Dropdown from './widgets/dropdown';
import EmailText from './widgets/email-text';
import List from './widgets/list';
import MenuGroup from './widgets/menu-group';
import MenuItem from './widgets/menu-item';
import { NoMore, LoadMore } from './widgets/no-more';
import Password from './widgets/password';
import Date from './widgets/date';
import ChoseDate from './widgets/chose-date';
import Integer from './widgets/integer';
import RowComments from './widgets/row-comments';
import FormRow from './widgets/form-row';
import Search from './widgets/search';
import SearchBar from './widgets/search-bar';
import Tab from './widgets/tab';
import Text from './widgets/text';
import TextArea from './widgets/textarea';
import UploadImg from './widgets/upload-img';

import Logo from './both/logo/logo';
import Toast from './both/toast/toast';
import Alert from './both/alert/alert';
import SearchBox from './both/search-box/SearchBox';
import Something from './both/something/something';
import PageLoading from './both/loading/page-loading';
import AnysignView from './both/anysign/anysignView';
import {
  Page,
  PageHeader,
  PageBody,
  PageFooter,
  PageBodyContainer,
  NotImpl,
  HomeCover,
} from './both/page/page';

import PolicyList from './mobile/policy/policy-list';
import ProductList from './mobile/product/product-list';
import TopBar from './mobile/top-bar/top-bar';
import LifeBottomMenu from './mobile/bottom-menu/bottom-menu';
import BottomMenu from './mobile/beijing-life/life-bottom-menu/life-bottom-menu';

import DeskTopMenu from './desk/desk-top-menu/desk-top-menu';
import DeskBottom from './desk/desk-bottom/desk-bottom';
import Pagination from './desk/pagination/pagination';
import DeskPolicy from './desk/policy/policy';
import DeskPolicyInput from './desk/policy-input/policy-input';

import OccupationSearch from './both/occupationSearch/SearchBox';
//进度条
import customBreadCrumb from './both/customBreadCrumb/customBreadCrumb';
//底部导航
import Tabbar from './tab/tabbar/tabbar';
//引导导航
import Lead from './tab/lead/lead';
//在线客服
import ServiceOnline from './tab/serviceOnline/serviceOnline';
//掼蛋活动
import BannerProject from './tab/bannerProject/bannerProject';
//养老活动
import RaiseOld from './tab/raiseOld/raiseOld';
//电子服务协议
import ServiceAge from './tab/serviceAge/serviceAge';
//好孕无忧
import CarefreeAge from './tab/carefreeAge/carefreeAge';
//女性俱乐部
import Fcm from './tab/fcm/fcm';
export {
  AsyncComponent,
  customBreadCrumb,
  Logo,
  Toast,
  Alert,
  SearchBox,
  Something,
  Page,
  PageBody,
  PageBodyContainer,
  PageFooter,
  PageHeader,
  NotImpl,
  HomeCover,
  PolicyList,
  ProductList,
  TopBar,
  BottomMenu,
  LifeBottomMenu,
  BottomButton,
  Button,
  CheckableContext,
  CheckableWidget,
  CheckBox,
  CheckMenuItem,
  Dropdown,
  EmailText,
  FailFastPromise,
  List,
  LoadMore,
  MenuGroup,
  MenuItem,
  NoMore,
  Password,
  Date,
  ChoseDate,
  Integer,
  ReactRouterContextWidget,
  RowComments,
  FormRow,
  Search,
  SearchBar,
  Tab,
  Text,
  TextArea,
  UploadImg,
  Widget,
  PageLoading,
  AnysignView,
  DeskTopMenu,
  DeskBottom,
  Pagination,
  DeskPolicy,
  DeskPolicyInput,
  OccupationSearch,
  Tabbar,
  Lead,
  ServiceOnline,
  BannerProject,
  RaiseOld,
  ServiceAge,
  CarefreeAge,
  Fcm,
};
